<template>
  <div class="studioAdminIndex public_main_bgc">
    <!-- 文章详情 -->
    <div class="ArrBox">
      <!-- 导航 -->
      <div class="title">

        <p v-if="routeName == 'BaseInfo'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>基本信息管理</span>
        </p>
        <p v-else-if="routeName == 'TearmAdmin'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>成员管理</span>
        </p>
        <p v-else-if="routeName == 'TearmAudit'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>成员审核</span>
        </p>
        <p v-else-if="routeName == 'NoticeManage'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>通知公告管理</span>
        </p>
        <p v-else-if="routeName == 'PushNotice'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <i>通知公告管理</i>
          <i> > </i>
          <span>发布公告</span>
        </p>
        <p v-else-if="routeName == 'LiveManage'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>直播课管理</span>
        </p>
        <p v-else-if="routeName == 'CreateLive'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <i>直播课管理</i>
          <i> > </i>
          <span>创建直播课</span>
        </p>
        <p v-else-if="routeName == 'RecordManage'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>录播课管理</span>
        </p>
        <p v-else-if="routeName == 'CreateRecord'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <i>录播课管理</i>
          <i> > </i>
          <span>创建录播课</span>
        </p>
        <p v-else-if="routeName == 'ActivityManage'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>教研活动管理</span>
        </p>
        <p v-else-if="routeName == 'CreateActivity'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <i>教研活动</i>
          <i> > </i>
          <span>创建活动</span>
        </p>
        <p v-else-if="routeName == 'ResourceList'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>研修资源管理</span>
        </p>
        <p v-else-if="routeName == 'ResourceManage'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>资源文件夹管理</span>
        </p>
        <p v-else-if="routeName == 'ArticleList'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>教师文章管理</span>
        </p>
        <p v-else-if="routeName == 'ReportList'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>活动报道管理</span>
        </p>
        <p v-else-if="routeName == 'ArticleType'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>文章分类管理</span>
        </p>
        <!-- <p v-else-if="routeName == 'TopicManage'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>话题管理</span>
        </p> -->
        <!-- <p v-else-if="routeName == 'TopicManageDetail'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <i>话题管理</i>
          <i> > </i>
          <span>话题详情</span>
        </p> -->
        <p v-else-if="routeName == 'HonorManage'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>成果展示管理</span>
        </p>
        <p v-else-if="routeName == 'CourseDiscuss'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>课程评论管理</span>
        </p>
        <p v-else-if="routeName == 'ArticleDiscuss'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>文章评论管理</span>
        </p>
        <p v-else-if="routeName == 'performancePage'">
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>工作室绩效考核</span>
        </p>
        <p v-else>
          当前位置：<span class="cursor" @click="onBackHome" style="color: #999">四名工作室</span>
          >
          <span>工作室管理</span>
        </p>
      </div>

      <div class="lowContent">
        <!-- 左边导航 -->
        <div v-if="detailData.role == 1" class="mainNav">
          <div class="back_div" @click="onBackHome"> {{ back_div }}返回</div>
          <!-- <div class="avtorBox">
            <div class="Pbox">
              <img :src="detailData.TeachingStudioinfo.photo_url" alt="" class="img_cover" />
            </div>
            <div class="fc studio_name">
              <span class="ellipsis">{{
                detailData.TeachingStudioinfo.realname
              }}</span>
              【
              <p class="ellipsis">
                {{ detailData.TeachingStudioinfo.teaching_studio_name }}
              </p>
              】
            </div>
            <div class="bq">工作室管理员
              <span class="temp_class_span_div">-jkk </span>
            </div>
          </div> -->
          <!-- //循环导航 -->
          <!-- <div class="navBox1" v-for="(item, index) in navList" :key="index">
            <p v-if="item.name">{{ item.name }}</p>
            <div :class="$route.path == list.url ? 'activeClass' : 'ht'" v-for="(list, idx) in item.child" :key="idx"
              @click="changeMain(list.id, list.url)">
              <img :src="$route.path == list.url ? list.img1 : list.img2" alt="" class="mr10" />
              <span>{{ list.name }}</span>
            </div>
          </div> -->

          <el-menu
            class="menu-module"
            default-active="2"
            background-color="transparent"
            text-color="#333"
            active-text-color="#3489FF"
            :router="true"
            :default-active="menuActive">
            <div v-for="(item, index) in navList" :key="index">
              <template v-if="!item.name">
                <el-menu-item :index="list.url" v-for="(list, idx) in item.child" :key="idx">
                  <img :src="$route.path == list.url ? list.img1 : list.img0" alt="" class="mr10" />
                  <span slot="title">{{ list.name }}</span>
                </el-menu-item>
              </template>
              <el-submenu :index="'i'+index" v-else>
                <template slot="title">
                  <img :src="item.img0" alt="" class="mr10" />
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item :index="list.url" v-for="(list, idx1) in item.child" :key="idx1">{{ list.name }}</el-menu-item>
              </el-submenu>
            </div>
          </el-menu>
          
        </div>

        <!-- 右边内容视图-->
        <div class="mainView">
          <router-view @getAdminData="getAdminData" @rouname="rouname"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      back_div: "<",
      //   active: "/AdminHome",
      navList: [
        {
          name: "",
          child: [
            {
              id: 1,
              name: "管理首页",
              url: "/AdminHome",
              img0: require("@/assets/images/studioHome/studio_nav_icon_home.png"),
              img1: require("@/assets/images/studioHome/studio_nav_icon_home_active.png"),
              img2: require("@/assets/images/teacherStudio/sy_h.png"),
            },
            {
              id: 2,
              name: "基本信息管理",
              url: "/BaseInfo",
              img0: require("@/assets/images/studioHome/studio_nav_icon_info.png"),
              img1: require("@/assets/images/studioHome/studio_nav_icon_info_active.png"),
              img2: require("@/assets/images/teacherStudio/base_h.png"),
            },
          ],
        },
        //第二个模块
        {
          name: "成员及公告管理",
          img0: require("@/assets/images/studioHome/studio_nav_icon_notice.png"),
          child: [
            {
              id: 3,
              name: "成员管理",
              url: "/TearmAdmin",
              img1: require("@/assets/images/teacherStudio/cy.png"),
              img2: require("@/assets/images/teacherStudio/cy_h.png"),
            },
            // {
            //   id: 4,
            //   name: "成员审核",
            //   url: "/TearmAudit",
            //   img1: require("@/assets/images/teacherStudio/check.png"),
            //   img2: require("@/assets/images/teacherStudio/check_h.png"),
            // },
            {
              id: 5,
              name: "通知公告管理",
              url: "/NoticeManage",
              img1: require("@/assets/images/teacherStudio/notice.png"),
              img2: require("@/assets/images/teacherStudio/notice_h.png"),
            },
          ],
        },
        //第三个模块
        // {
        //   name: "四名讲堂管理",
        //   img0: require("@/assets/images/studioHome/studio_nav_icon_notice.png"),
        //   child: [
        //     {
        //       id: 6,
        //       name: "直播课管理",
        //       url: "/LiveManage",
        //       img1: require("@/assets/images/teacherStudio/live.png"),
        //       img2: require("@/assets/images/teacherStudio/live_h.png"),
        //     },
        //     {
        //       id: 7,
        //       name: "录播课管理",
        //       url: "/RecordManage",
        //       img1: require("@/assets/images/teacherStudio/lb.png"),
        //       img2: require("@/assets/images/teacherStudio/lb_h.png"),
        //     },
        //   ],
        // },
        //第四个模块
        // {
        //   name: "",
        //   child: [

        //     {
        //       id: 10,
        //       name: "资源文件夹管理",
        //       url: "/ResourceManage",
        //       img1: require("@/assets/images/teacherStudio/floader.png"),
        //       img2: require("@/assets/images/teacherStudio/floader_h.png"),
        //     },
        //   ],
        // },
        //第五个模块
        {
          name: "活动及资源管理",
          img0: require("@/assets/images/studioHome/studio_nav_icon_resource.png"),
          child: [
            {
              id: 6,
              name: "直播课管理",
              url: "/LiveManage",
              img1: require("@/assets/images/teacherStudio/live.png"),
              img2: require("@/assets/images/teacherStudio/live_h.png"),
            },
            {
              id: 7,
              name: "录播课管理",
              url: "/RecordManage",
              img1: require("@/assets/images/teacherStudio/lb.png"),
              img2: require("@/assets/images/teacherStudio/lb_h.png"),
            },
            {
              id: 8,
              name: "教研活动管理",
              url: "/ActivityManage",
              img1: require("@/assets/images/teacherStudio/activity.png"),
              img2: require("@/assets/images/teacherStudio/activity_h.png"),
            },
            {
              id: 9,
              name: "研修资源管理",
              url: "/ResourceList",
              img1: require("@/assets/images/teacherStudio/resource.png"),
              img2: require("@/assets/images/teacherStudio/resource_h.png"),
            },
            {
              id: 11,
              name: "教师文章管理",
              url: "/ArticleList",
              img1: require("@/assets/images/teacherStudio/art.png"),
              img2: require("@/assets/images/teacherStudio/art_h.png"),
            },
            {
              id: 12,
              name: "活动报道管理",
              url: "/ReportList",
              img1: require("@/assets/images/teacherStudio/fl.png"),
              img2: require("@/assets/images/teacherStudio/fl_h.png"),
            },
            // {
            //   id: 13,
            //   name: "话题管理",
            //   url: "/TopicManage",
            //   img1: require("@/assets/images/teacherStudio/topic.png"),
            //   img2: require("@/assets/images/teacherStudio/topic_h.png"),
            // },
            {
              id: 14,
              name: "研究成果管理",
              url: "/ResearchAchievement",
              img1: require("@/assets/images/teacherStudio/zs.png"),
              img2: require("@/assets/images/teacherStudio/zs_h.png"),
            },
            {
              id: 14,
              name: "研究课题管理",
              url: "/ResearchTopic",
              img1: require("@/assets/images/teacherStudio/zs.png"),
              img2: require("@/assets/images/teacherStudio/zs_h.png"),
            },
            // {
            //   id: 15,
            //   name: "成果展示管理",
            //   url: "/HonorManage",
            //   img1: require("@/assets/images/teacherStudio/zs.png"),
            //   img2: require("@/assets/images/teacherStudio/zs_h.png"),
            // },
          ],
        },
        //第六个模块
        {
          name: "评论管理",
          img0: require("@/assets/images/studioHome/studio_nav_icon_comment.png"),
          child: [
            {
              id: 15,
              name: "课程评论管理",
              url: "/CourseDiscuss",
              img1: require("@/assets/images/teacherStudio/comment.png"),
              img2: require("@/assets/images/teacherStudio/comment_h.png"),
            },
            {
              id: 16,
              name: "文章评论管理",
              url: "/ArticleDiscuss",
              img1: require("@/assets/images/teacherStudio/pj.png"),
              img2: require("@/assets/images/teacherStudio/pj_h.png"),
            },
          ],
        },
        //第八个模块（公众号转发管理）
        {
          name: "公众号转发管理",
          img0: require("@/assets/images/studioHome/studio_nav_icon_push.png"),
          child: [
            {
              id: 15,
              name: "公众号素材管理",
              url: "/msShuCai",
              img1: require("@/assets/images/teacherStudio/lb.png"),
              img2: require("@/assets/images/teacherStudio/lb_h.png"),
            },
            {
              id: 16,
              name: "公众号绑定管理",
              url: "/msBangDing",
              img1: require("@/assets/images/teacherStudio/activity.png"),
              img2: require("@/assets/images/teacherStudio/activity_h.png"),
            },
          ],
        },
        //第七个模块（绩效考核）
        {
          name: "",
          //img0: require("@/assets/images/studioHome/studio_nav_icon_comment.png"),
          child: [
            {
              id: 17,
              name: "工作室绩效考核",
              url: "/performancePage",
              img0: require("@/assets/images/studioHome/studio_nav_icon_examine.png"),
              img1: require("@/assets/images/studioHome/studio_nav_icon_examine_active.png"),
              img2: require("@/assets/images/teacherStudio/performance_h.png"),
            },
          ],
        },
      ],
      detailData: {
        role: 0,
        TeachingStudioinfo: {},
      },
      routeName: "",
      menuActive: ""
    };
  },
  created() {
    let path = this.$route.path;
    path = this.pathFilter(path);
    this.menuActive = path;
    if (localStorage.getItem("studioId")) {
      this.getAdminData();
    } else {
      this.$router.push({
        path: "/teacherstudio",
      })
    }
  },
  computed: {},
  methods: {
    rouname(val) {
      this.routeName = val
      console.log(this.routeName);
    },
    changeMain(id, url) {
      this.$router.push(url);
    },
    onBackHome() {
      this.$router.push("/TeachLectureIndex");
    },
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId") || this.$route.query.studioId,
        },
      }).then((res) => {
        this.detailData = res.data.data;
        // if (this.detailData.role != 1) {
        //   this.$router.push({
        //     path: "/TeachLectureIndex",
        //     query: {
        //       id: localStorage.getItem("studioId"),
        //       teaching_studio_id: localStorage.getItem("studioId"),
        //     }
        //   })
        // }
      });
    },
    //路径过滤
    pathFilter(path) {
      if (path == '/TearmAudit') {
        path = '/TearmAdmin';
      }
      if (path == '/PushNotice') {
        path = '/NoticeManage';
      }
      if (path == '/CreateLive') {
        path = '/LiveManage';
      }
      if (path == '/CreateRecord') {
        path = '/RecordManage';
      }
      if (path == '/PushActivity' || path == '/CreateActivity') {
        path = '/ActivityManage';
      }
      if (path == '/uploadResSet' || path == '/ResourceManage') {
        path = '/ResourceList';
      }
      if (path == '/AddArticleSet' || path == '/ArticleType') {
        path = '/ArticleList';
      }
      if (path == '/addReportIndexSet') {
        path = '/ReportList';
      }
      if (path == '/ResearchAchievementEdit') {
        path = '/ResearchAchievement';
      }
      if (path == '/ResearchTopicEdit') {
        path = '/ResearchTopic';
      }
      return path;
    }
  },
  watch: {
    // 监听，当路由发生变化的时候执行
    $route(to, from) {
      let path = to.path;
      path = this.pathFilter(path);
      this.menuActive = path;
    },
  },
};
</script>
<style lang="less" scoped>
.studioAdminIndex {
  padding-bottom: 50px;

  .ArrBox {
    width: 1400px;
    margin: 0 auto;

    .title {
      height: 48px;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #999999;
      display: flex;
      align-items: center;

      p {
        span {
          color: #ff8201;
        }
      }
    }
  }

  .lowContent {
    display: flex;
    width: 100%;

    .mainNav {
      width: 274px;
      flex: 0 0 274px;
      background: linear-gradient(0deg, rgba(218, 237, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
      border-radius: 8px;
      overflow: hidden;
      margin-right: 20px;
      padding: 0 20px 28px;
      box-sizing: border-box;
      position: relative;

      .back_div {
        width: 63px;
        height: 27px;
        background: rgba(173, 187, 202, 1);
        border-radius: 0 0 8px 0;
        font-size: 14px;
        line-height: 27px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      .avtorBox {
        width: 100%;

        .Pbox {
          width: 70px;
          height: 70px;
          margin: 0 auto;
          margin-top: 21px;
          margin-bottom: 21px;

          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }
        }

        .studio_name {
          margin-bottom: 14px;
          color: #666;

          >span {
            font-weight: 600;
            color: #333;
            width: 50px;
          }

          >p {
            max-width: 150px;
          }
        }

        .bq {
          width: 102px;
          height: 22px;
          margin: 0 auto;
          background: #f8efe6;
          border: 1px solid #ff8201;
          border-radius: 2px;
          text-align: center;
          color: #ff8201;
        }
      }

      //第一个nav
      .navBox1 {
        cursor: pointer;
        //border-top: 1px solid #f0f0f0;
        padding-top: 15px;
        margin-top: 10px;

        p:nth-of-type(1) {
          width: 150px;
          margin-left: 2px;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }

        //未激活样式
        .ht {
          height: 36px;
          // background: #fff;
          border-radius: 6px;
          display: flex;
          align-items: center;
          padding: 5px;
          padding-left: 10px;

          .tB {
            // width: 14px;
            // height: 17px;
            // img {
            //   width: 14px;
            //   height: 17px;
            // }
          }
        }

        //激活样式
        .activeClass {
          height: 36px;
          background: rgba(52, 137, 255, 1);
          color: #fff;
          border-radius: 6px;
          display: flex;
          align-items: center;
          position: relative;
          padding: 5px;
          padding-left: 10px;
          font-weight: bold;

          .tB {
            // width: 14px;
            // height: 17px;
            // img {
            //   width: 14px;
            //   height: 17px;
            // }
          }

          &::after {
            position: absolute;
            content: ">";
            color: #3489ff;
            right: 11px;
            top: 12px;
          }
        }
      }
    }

    .mainView {
      flex: 1;
      background: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      min-height: 85vh;
    }

    .mainView {
      /deep/.GroupCreateLive,
      /deep/.createCourse_div {
        border-radius: 8px;
        overflow: hidden;
        .mainBox {
          width: 100%;
        }
      }
      /deep/.uploadBox,
      /deep/.pushBox {
        border-radius: 8px;
        overflow: hidden;
        .ArrBox {
          width: 100%;
        }
      }
    }
  }
}

/deep/.menu-module {
  border-right: 0;
  margin-top: 40px;
  .el-menu-item,
  .el-submenu__title {
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    padding: 0 10px !important;
    border-radius: 8px;
    &:hover {
      background: transparent !important;
    }
  }
  .el-menu--inline {
    padding-left: 30px;
  }
  .el-menu-item.is-active {
    color: #fff !important;
    background: #3489FF !important;
  }
  .el-submenu__icon-arrow {
    font-size: 14px;
    color: #899099;
    font-weight: bold;
  }
  .is-opened {
    .el-submenu__icon-arrow {
      color: #3489FF;
    }
  }
}
</style>
